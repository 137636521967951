/* stylelint-disable property-no-unknown */
/* stylelint-disable at-rule-no-unknown */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .quick-link {
    @apply w-8 h-8 p-1 text-gray-300 animate-fade-in-right hover:text-orange-400 hover:scale-110;
  }

  .icon {
    @apply w-full fill-current;
  }
}

@layer utilities {
  .font-effect-anaglyph {
    text-shadow: -0.06em 0 red, 0.06em 0 cyan;
  }

  .font-effect-3d {
    /* stylelint-disable-next-line max-line-length */
    text-shadow: 0 1px 0 #c7c8ca, 0 2px 0 #b1b3b6, 0 3px 0 #9d9fa2, 0 4px 0 #8a8c8e, 0 5px 0 #77787b, 0 6px 0 #636466, 0 7px 0 #4d4d4f, 0 8px 7px #001135;
  }
}
